import './App.css';
import {createRef, useEffect} from "react";

function App() {
    const bubbleColors = ['black', 'white'];

    const mainDiv = createRef();

    const createBubble = () => {
        const createElement = document.createElement('span');
        const size = Math.random() * 60;

        createElement.style.width = `${20 + size}px`;
        createElement.style.height = `${20 + size}px`;
        // eslint-disable-next-line no-restricted-globals
        createElement.style.left = `${Math.random() * innerWidth}px`;

        createElement.style.background = bubbleColors[Math.floor(Math.random() * bubbleColors.length)];
        // createElement.style.border = '1px solid rgba(0,0,0, 0.5)';
        createElement.style.zIndex = '-9999';

        mainDiv.current?.appendChild(createElement);

        setTimeout(() => {
            createElement.remove();
        }, 4000);
    }

    useEffect(() => {
        setInterval(createBubble, 200);
    }, [createBubble]);

    return (
        <div
            ref={mainDiv}
            style={{
                position: 'relative',
                width: '100%',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <img src="/assets/img/logo.png" alt="Ante logotipo" style={{width: '30%'}}/>
            <p style={{ fontWeight: 'bold', fontSize: 'x-large' }}>ANTE Software Development</p>
        </div>
    );
}

export default App;
